import { russianDefaultPaymentCards } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer  = {
	cinema: {
		address: 'ул. Армейская, 1',
		place: null,
		phoneNumbers: ['+7 (40155) 2-17-77'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/kino_mayak',
		twitter: null,
		odnoklassniki: null,
		youtube: 'https://www.youtube.com/channel/UCJsVqip7eaQqFwWrQM38D0Q',
		app: {
			appstore: 'https://itunes.apple.com/ru/app/кинотеатр-маяк-билеты-в-кино/id1210358306',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.mayak',
		},
		payment: {
			active: true,
			card: russianDefaultPaymentCards,
		},
		maps: 'https://yandex.ru/maps/-/C6QRIGL9',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
};

